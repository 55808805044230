const segments = {
    "no": {
        business: "bedrift",
        private: "privat",
    },
    "en": {
        business: "business",
        private: "personal",
    },
    "dk": {
        business: "erhverv",
        private: "privat",
    },
    "se": {
        business: "foretag",
        private: "privat",
    },
} as const

export default segments
