import { DocQuery, TemplateType } from "src/templates"
import { getClient } from "./sanity.server"
import segments from "./segments"
import { blogPostExists, blogPostExistsInDraft } from "./selectors"
import * as translate from "./translate"

export interface SlugQuery {
    docType: TemplateType
    queryParams: { slug?: string, locale: string, segment?: string, blogCategory?: string }
    query: string
}

export async function getQueryFromSlug(
    slug: string[] = [],
    locale?: string,
    isPreview = false,
): Promise<SlugQuery> {
    const [localSegment, ...url] = slug
    const segment = translate.segment(localSegment)
    if (segments[locale]?.[segment] === localSegment && url.length === 0) {
        return {
            docType: "frontpage",
            queryParams: {
                locale,
                segment,
            },
            query: DocQuery.frontpage,
        }
    } else if ((["dk", "en"].includes(locale) && url[0] === "blog") || (["se", "no"].includes(locale) && url[0] === "blogg")) {
        if (url.length === 1) {
            // Blog overviewpage (/dk/privat/blog)
            return {
                docType: "blogOverview",
                queryParams: {
                    locale,
                    segment,
                },
                query: DocQuery.blogOverview,
            }
        } else if (url.length == 2 && url[1].startsWith("p-")) {
            // Blog overviewpage paged (/dk/privat/blog/p-2)
            const page = parseInt(url[1].split("-")[1], 10)
            const pageOffset = `${(page-1) * 15}...${((page) * 15) + 1}`
            const query = DocQuery.blogOverview.replace("0...16", pageOffset)

            return {
                docType: "blogOverview",
                queryParams: {
                    locale,
                    segment,
                },
                query,
            }
        } else if (url.length >= 3) {
            // Blog post (/dk/privat/blog/investing/hvordan-sparer-du-op)

            const client = getClient(isPreview, true)
            const queryParams = {
                locale,
                segment,
                slug: url.slice(-1)[0],
            }
            const res = await client.fetch(isPreview ? blogPostExistsInDraft : blogPostExists, queryParams)

            if (res.length > 0) {
                return {
                    docType: "blogPost",
                    queryParams: {
                        locale,
                        segment,
                        slug: url.slice(-1)[0],
                        blogCategory: url.slice(-2)[0],
                    },
                    query: DocQuery.blogPost,
                }
            }
        }
    }

    if (url[0] == "in-app") {
        return {
            docType: "inAppPage",
            queryParams: {
                slug: url.slice(1).join("/"),
                locale,
                segment,
            },
            query: DocQuery.inAppPage,
        }
    }

    return {
        docType: "page",
        queryParams: {
            // We now have to re-combine the slug array to match our slug in Sanity.
            slug: url.filter(i => i).join("/"),
            locale,
            segment,
        },
        query: DocQuery.page,
    }
}
