import { createClient as sanityCreateClient } from "next-sanity"
import Config from "src/Config"
import { config } from "./config"
// import { createClient as redisCreateClient } from "redis"
import { SlugQuery } from "./getQueryFromSlug"
import { GlobalSettings } from "./types"
// import * as Sentry from "@sentry/nextjs"

// const redisClient = redisCreateClient({
//     socket: {
//         host : "eu2-social-oyster-30816.upstash.io",
//         port : 30816,
//     },
//     password: "1b72f20e716147349be81eda31429e82",
// })
// redisClient.on("error", (err) => err?.name?.includes("Socket closed unexpectedly") ? null : Sentry.captureException)

// Set up the client for fetching data in the getProps page functions
export const sanityClient = sanityCreateClient(config)
export const sanityClientNoCdn = sanityCreateClient({ ...config, useCdn: false })

// Set up a preview client with serverless authentication for drafts
export const previewClient = sanityCreateClient({
    ...config,
    useCdn: false,
    token: Config.SANITY_PREVIEW_SECRET,
    withCredentials: true,
    perspective: "previewDrafts",
})

// Helper function for easily switching between normal client and preview client
export function getClient(usePreview: boolean, useCdn = false) {
    if (usePreview) return previewClient
    if (!useCdn) return sanityClientNoCdn
    return sanityClient
}

// Optimize the query for globalSettings with in memory cache and redis - to reduce Sanity bandwidth
const globalSettingsCache = {}
export async function getGlobalSettings(selector: SlugQuery["query"], queryParams: SlugQuery["queryParams"]) {
    const cacheKey = ["globalSettings", config.dataset, queryParams.locale, queryParams.segment].join("-")
    if (globalSettingsCache[cacheKey]) {
        return globalSettingsCache[cacheKey]
    }

    // try {
    //     if (!redisClient.isOpen)
    //         await redisClient.connect()
    //     const value = await redisClient.get(cacheKey)
    //     if (value) {
    //         globalSettingsCache[cacheKey] = JSON.parse(value)
    //         return JSON.parse(value)
    //     }
    // } catch(error) {
    //     Sentry.captureException(error)
    // }

    // Get fresh when we don't have cache
    const res = await sanityClient.fetch<GlobalSettings>(selector, queryParams)
    globalSettingsCache[cacheKey] = res
    setTimeout(() => {delete globalSettingsCache[cacheKey]}, 1000*60 )
    // try {
    //     await redisClient.setEx(cacheKey, 60*30, JSON.stringify(res))
    // } catch (error) {
    //     Sentry.captureException(error)
    // }
    return res
}
