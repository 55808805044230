// gets i18n segment as english enum segment
import segments from "./segments"

const businessSegments = Object.entries(segments).map((e) => e[1].business)
const privateSegments = Object.entries(segments).map((e) => e[1].private)

export function segment(name: string) {
    if ((businessSegments as string[]).includes(name)) {
        return "business"
    }

    if ((privateSegments as string[]).includes(name)) {
        return "private"
    }

    return "private"
}

// gets i18n products prefix as english enum
export function products(name: string): "products" {
    switch (name) {
        case "produkter":
        case "products":
            return "products"
    }
}

// gets i18n products prefix as english enum
export function subscriptions(name: string): "subscriptions" {
    switch (name) {
        case "priser":
        case "prisor":
            return "subscriptions"
    }
}
