
import * as Sentry from "@sentry/nextjs"
import { NextPageContext } from "next"
import { getClient, getGlobalSettings } from "src/sanity/sanity.server"
import { GlobalSettings, HeaderStyle, Module, SEOData } from "src/sanity/types"
import * as selectors from "../src/sanity/selectors"
import { getQueryFromSlug } from "src/sanity/getQueryFromSlug"
import Page from "src/layout/page/Page"
import { DocQuery, DocTypeMap } from "src/templates"
import ModuleList from "src/components/module-list/ModuleList"
import { pickDraft } from "./[[...slug]]"

interface SubPage {
    modules: Module[]
    title: string
    headerStyle: HeaderStyle
    seo: SEOData
}

interface ErrorViewProps {
    statusCode: number
    hasGetInitialPropsRun: boolean
    locale: string
    resolvedUrl: string
    err: NextPageContext["err"]
    globalSettings: GlobalSettings
    page: SubPage
}

function ErrorView({
    globalSettings,
    page,
}: ErrorViewProps): JSX.Element {
    return (
        <Page
            headerStyle={page?.headerStyle || "dark"}
            globalSettings={globalSettings}
            pageTitle={page?.title || ""}
        >
            <ModuleList modules={page?.modules} />
        </Page>
    )
}

interface SentryErrorProps {
    locale: string
    globalSettings: GlobalSettings
    resolvedUrl: string
    page: SubPage
}

ErrorView.getInitialProps = async (context: NextPageContext): Promise<SentryErrorProps> => {
    const { err, asPath, res } = context
    let { locale } = context
    const client = getClient(false)

    if (!locale || locale === "default") {
        locale = "dk"
    }
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404

    const { queryParams } = await getQueryFromSlug(asPath.split("/").slice(1), locale)

    const [globalSettings] = await Promise.all([
        getGlobalSettings(selectors.globalSettings, queryParams),
    ])

    // Running on the server, the response object (`res`) is available.
    //
    // Next.js will pass an err on the server if a page's data fetching methods
    // threw or returned a Promise that rejected
    //
    // Running on the client (browser), Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html
    if (statusCode !== 404 && statusCode !== 405) {
        Sentry.captureUnderscoreErrorException(context)
        if (err) {
            Sentry.captureException(err)

            // Flushing before returning is necessary if deploying to Vercel, see
            // https://vercel.com/docs/platform/limits#streaming-responses
            //await Sentry.flush(2000)

            //return errorInitialProps
        } else {
            // If this point is reached, getInitialProps was called without any
            // information about what the error might be. This is unexpected and may
            // indicate a bug introduced in Next.js, so record it in Sentry
            Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
        }

        await Sentry.flush(2000)
    }

    const args = statusCode === 404 ? globalSettings.notfoundPage?.url : (globalSettings.errorPage?.url || globalSettings.notfoundPage?.url)
    let page
    try {
        page = await client.fetch<DocTypeMap["page"]>(DocQuery["page"], {
            slug: args.slug,
            locale: args.lang,
            segment: args.segment,
        })
    } catch(err) {
        Sentry.captureException(err)
    }

    return {
        page: pickDraft(page, false),
        locale,
        resolvedUrl: asPath,
        globalSettings,
    }
}

export default ErrorView
